<script>
import api from "@/services/api";
import { v3ServiceApi } from "@/services/v3/v3.service";

import Permission from "@/permission";

import BaseCard from "@/views/home/elements/BaseCard.vue";

export default {
  name: "BotPage",
  components: {
    BaseCard,
  },
  data() {
    return {
      allLangs: [],
      currentLang: "",
      stats: null,
      loading: false,
      permission: {
        create: Permission.getUserPermission("bot.create"),
        delete: Permission.getUserPermission("bot.delete"),
      },
    };
  },
  computed: {
    tableFields() {
      return [
        {
          key: "title",
          label: this.$t("bot.table_title"),
          thStyle: "width: 25%",
        },
        {
          key: "description",
          label: this.$t("bot.description"),
        },
        {
          key: "slug",
          label: this.$t("bot.slug"),
          thStyle: "width: 25%",
        },
        {
          key: "actions",
          label: "",
          thStyle: "width: 100px",
        },
      ];
    },
  },
  created() {
    api.languagesV3.getAllLanguages().then((res) => {
      this.allLangs.push(...res.data.result);
      this.currentLang = this.allLangs[0];
    });
    this.fetchItems();
  },
  methods: {
    setTab(e) {
      this.currentLang = e;
    },
    startLoading() {
      this.loading = true;
    },
    finishLoading() {
      this.loading = false;
    },

    async fetchItems() {
      try {
        this.startLoading();
        const response = await v3ServiceApi.botPages.statistics();
        this.stats = response.data.result;
      } catch (e) {
        this.toastedWithErrorCode(e);
      } finally {
        this.finishLoading();
      }
    },
  },
};
</script>

<template>
  <div
    class="app-settings-client-type"
    style="display: grid; grid-template-columns: repeat(4, 1fr); gap: 12px"
  >
    <base-card
      style="width: 100%"
      icon="location_city"
      :title="`${$t('contracts.name')}`"
      :multiple="false"
    >
      <template #bottom>
        <span class="mt-4 d-inline-block">{{ stats.orders }}</span>
      </template>
    </base-card>

    <base-card
      style="width: 100%"
      icon="location_city"
      :title="`${$t('bot.active_users')}`"
      :multiple="false"
    >
      <template #bottom>
        <span class="mt-4 d-inline-block">{{ stats.active_users }}</span>
      </template>
    </base-card>

    <base-card
      style="width: 100%"
      icon="location_city"
      :title="`${$t('bot.verified_users')}`"
      :multiple="false"
    >
      <template #bottom>
        <span class="mt-4 d-inline-block">{{ stats.verified_users }}</span>
      </template>
    </base-card>

    <base-card
      style="width: 100%"
      icon="location_city"
      :title="`${$t('bot.total_users')}`"
      :multiple="false"
    >
      <template #bottom>
        <span class="mt-4 d-inline-block">{{ stats.total_users }}</span>
      </template>
    </base-card>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/utils/pagination";

@import "@/assets/scss/utils/b-table-redesign.scss";
@import "@/views/settings/assets/crudTable.scss";
.translation-tag {
  padding: 6px 12px;
  &:not(:last-child) {
    margin-right: 5px;
  }
}
.row-opacity {
  opacity: 0.2;
}
.tag:not(:first-child) {
  margin-top: 10px;
}
</style>
